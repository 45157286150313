import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Textarea,
  useToast,
  Text,
} from '@chakra-ui/react';
import { useState } from 'react';

import { useAppStateContext } from '../../../../context/appContext';
import { getErrorMessage } from '../../../../utils/errorUtils';
import { createMorphStoreItem } from '../services/morphStore';

interface AddStoreItemsModalProps {
  onClose: () => void;
  onSuccess: () => void;
}

export default function AddMorphStoreItemsModal({
  onClose,
  onSuccess,
}: AddStoreItemsModalProps) {
  const state = useAppStateContext()?.state;
  const token = state?.token ?? '';
  const toast = useToast();
  const [isOpen, setIsOpen] = useState(true);
  const [itemsText, setItemsText] = useState('');
  const [canSubmit, setCanSubmit] = useState(false);

  function tsvToItems(tsv: string): CreateMorphStoreItemDto[] {
    /**
     * This function should take a tab separated string and return an array of items
     */
    const items = tsv.split('\n').map((line) => {
      let name = '',
        visibleName = '',
        description = '',
        amount = '',
        category = '',
        subcategory = '',
        order: string | null = null,
        credit = 'false',
        consumable = 'false',
        unlockConditions = '';
      const row = line.split('\t');
      if (row.length === 10) {
        [
          name,
          visibleName,
          description,
          amount,
          category,
          subcategory,
          order,
          credit,
          consumable,
          unlockConditions
        ] = row;
      } else if (row.length === 8) {
        [
          name,
          visibleName,
          description,
          amount,
          category,
          subcategory,
          credit,
          consumable,
        ] = row;
      }

      const item = {
        name,
        visibleName,
        description,
        amount: parseInt(amount),
        category,
        subcategory,
        unlockConditions
      } as CreateMorphStoreItemDto;

      if (order) {
        item.order = parseInt(order);
      }

      if (credit.toLowerCase() === 'true') {
        item.isCredit = true;
        item.omitInventory = true;
      }

      if (consumable.toLowerCase() === 'true') {
        item.isConsumable = true;
      }
      return item;
    });

    return items;
  }

  async function sendAllRequests(items: CreateMorphStoreItemDto[]) {
    /**
     * This function should send a request to create each item in the items array
     * It first determines which items are sets and which are not
     * It then creates the non-set items, and stores the item id in a dictionary
     * with the item name as the key and an array of item ids as the value.
     * It then creates the set items, using the dictionary to get the children
     */
    let successCount = 0;
    const nonSetItems = items.filter((item) => item.subcategory !== 'set');
    const setItems = items.filter((item) => item.subcategory === 'set');
    const itemIds: Record<string, string[]> = {};
    for (const item of nonSetItems) {
      try {
        const response = await createMorphStoreItem(token, item);
        const newStoreItem = JSON.parse(
          response.body
        ) as CreateMorphStoreItemResponse;
        // Add the new item id to the dictionary as an array in case there are multiple items with the same name
        const itemId = newStoreItem.storeItem.id;
        if (itemIds[item.name]) {
          itemIds[item.name].push(itemId);
        } else {
          itemIds[item.name] = [itemId];
        }
        successCount++;
      } catch (error) {
        toast({
          title: `Failed to create item ${item.name}`,
          description: getErrorMessage(error),
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      }
    }

    for (const item of setItems) {
      const children = itemIds[item.name];
      try {
        await createMorphStoreItem(token, { ...item, children });
        successCount++;
      } catch (error) {
        toast({
          title: `Failed to create item ${item.name}`,
          description: getErrorMessage(error),
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      }
    }

    toast({
      title: 'Items Created',
      description: `Successfully created ${successCount} items out of ${items.length}`,
      status: 'success',
      duration: 5000,
      isClosable: true,
    });

    onSuccess();
  }

  function handleOnClose() {
    setIsOpen(false);
    onClose();
  }

  function handleOnSubmit() {
    const items = tsvToItems(itemsText);
    sendAllRequests(items);
  }

  function onTextChange(e: React.ChangeEvent<HTMLTextAreaElement>) {
    setItemsText(e.target.value);
    if (e.target.value.length > 0) {
      setCanSubmit(true);
    }
  }

  return (
    <Modal isOpen={isOpen} onClose={handleOnClose} size="6xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Add Store Items</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Text>
            Enter a tabbed separated list of items to add with the following
            fields
          </Text>
          <Text style={{ fontWeight: 'bold' }}>
            {
              'Name\t"Visible Name"\tDescription\tPrice\tCategory\tSubcategory\tOrder\tCredit\tConsumable\tunlockConditions'
            }
          </Text>
          <Textarea value={itemsText} onChange={onTextChange} placeholder="" />
        </ModalBody>
        <ModalFooter>
          <Button
            colorScheme="blue"
            isDisabled={!canSubmit}
            onClick={handleOnSubmit}
          >
            Submit
          </Button>
          <Button onClick={handleOnClose} ml={4}>
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
